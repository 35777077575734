var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

var iconv_package;
var Iconv;

try {
  // this is to fool browserify so it doesn't try (in vain) to install iconv.
  iconv_package = "iconv";
  Iconv = _nullRequire(iconv_package).Iconv;
} catch (E) {// node-iconv not present
}

exports = Iconv;
export default exports;